@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700,500,300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.h-full {
  height: 100%;
}



.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(//beavermeadowsgolf.org/images/hero-dark.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(100%);
}

.sub-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(//beavermeadowsgolf.org/images/hero-dark.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(100%);
  
}

.subpage-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(//beavermeadowsgolf.org/images/hero-dark.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(100%);
}

.player-wrapper {
  position: relative !important;
padding-top: 56.25%
}

.react-player {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.video {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  border: 0;
}

@media (prefers-color-scheme: light) {
  .hero::before {
    background: url(//beavermeadowsgolf.org/images/hero-light.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .subpage-hero::before {
    background: url(//beavermeadowsgolf.org/images/hero-light.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

@media (prefers-color-scheme: dark) {
  .hero::before {
    background: url(//beavermeadowsgolf.org/images/hero-dark.jpg);
    background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  }

  .subpage-hero::before {
    background: url(//beavermeadowsgolf.org/images/hero-dark.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}